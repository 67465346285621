import React from "react";
import styles from './WhyCustomers.module.scss';
import boxImage1 from '../../../../assets/images/box1.svg';
import boxImage2 from '../../../../assets/images/box2.svg';
import boxImage3 from '../../../../assets/images/box3.svg';
import boxImage4 from '../../../../assets/images/box4.svg';
import rocketBg from '../../../../assets/backgrounds/rocketBG.png';
import WhyBox from "./WhyBox";

const WhyCustomers = ({text}) => {
    const customers = [
        {
            icon: boxImage1,
            title: text.box1title,
            description: text.box1text,
        },
        {
            icon: boxImage2,
            title: text.box2title,
            description: text.box2text,
        },
        {
            icon: boxImage3,
            title: text.box3title,
            description: text.box3text,
        },
        {
            icon: boxImage4,
            title: text.box4title,
            description: text.box4text,
        }
    ]

    return (
        <div className={styles.whyCustomers}>
            <img className={styles.decor} src={rocketBg} alt=""/>
            <div className={styles.header}>
                <h2>{text.customersTitle}</h2>
                <p className={styles.header__subTitle}>{text.customersSubTitle}</p>
            </div>
            <div className={styles.wrapper}>
                {customers && (
                    customers.map((item, index) => <WhyBox key={index} item={item} />)
                )}
            </div>
        </div>
    )
}

export default WhyCustomers;