import React from "react";
import './followNews.scss';
import styles from './FollowNews.module.scss';
import arrowLeft from '../../../../assets/icons/arrowLeft.svg';
import arrowRight from '../../../../assets/icons/arrowRight.svg';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, A11y} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {useAppSelector} from "../../../../hooks/redux";
import {getBlogs} from "../../../../store/selectors";
import {NavLink} from "react-router-dom";


const FollowNews = ({title, viewText}) => {
    const blogs = useAppSelector(getBlogs);

    return (
        <div className={`${styles.followNews} followNews`}>
            <Swiper
                modules={[Navigation, A11y]}
                spaceBetween={window.innerWidth > 1023 ? 50 : 11}
                slidesPerView={window.innerWidth > 1023 ? 3.5
                    : window.innerWidth > 767 && window.innerWidth < 1023 ? 3
                        : window.innerWidth > 540 && window.innerWidth < 767 ? 2 : 1.4}
                navigation={window.innerWidth > 1023}
                pagination={{clickable: true}}
                scrollbar={{draggable: true}}
            >
                <div className={styles.followNews__header}>
                    <h2>{title}</h2>
                    {window.innerWidth > 1023 && (
                        <div className={styles.followNews__arrows}>
                            <button onClick={() => swiper.slideNext()}>
                                <img src={arrowLeft} alt=""/>
                            </button>
                            <button onClick={() => swiper.slideNext()}>
                                <img src={arrowRight} alt=""/>
                            </button>
                        </div>
                    )}
                </div>
                <div className={styles.sliderWrapper}>
                    {blogs && (
                        blogs.map((blog, index) => (
                            <SwiperSlide key={index}>
                                <div className={styles.blog}>
                                    <img src={blog.image} alt=""/>
                                    <div className={styles.blog__content}>
                                        <div className={styles.blog__right}>
                                            <p>{blog.title}</p>
                                            <NavLink>{viewText}</NavLink>
                                        </div>
                                    </div>
                                </div>

                            </SwiperSlide>
                        ))
                    )}


                </div>

            </Swiper>
        </div>
    )
}

export default FollowNews;