import React from "react";
import {TVChartContainer} from "../../../TVChartContainer";
import styles from './Spot.module.scss';
import SpotOrderForm from "./SpotOrderForm/SpotOrderForm";

const Spot = ({isAuth = true}) => {
    return (
        <div className={styles.spot}>
            <div className={styles.chartWrapper}>
                <TVChartContainer />
            </div>
            <div className={styles.spotBookWrapper}>

            </div>
            <SpotOrderForm />
        </div>
    )
}

export default Spot;