import React from "react";
import styles from './WhyCustomers.module.scss';
import decorX from '../../../../assets/backgrounds/lineDecorX.svg';
import decorY from '../../../../assets/backgrounds/lineDecorY.svg';

const WhyBox = ({item}) => {
    return (
        <div className={styles.box}>
            {window.innerWidth > 767 && (
                <>
                    <img className={styles.top} src={decorX} alt=""/>
                    <img className={styles.bottom} src={decorX} alt=""/>
                    <img className={styles.left} src={decorY} alt=""/>
                    <img className={styles.right} src={decorY} alt=""/>
                </>
            )}


            <img src={item.icon} alt=""/>
            <p className={styles.box__title}>{item.title}</p>
            <p className={styles.box__text}>{item.description}</p>
        </div>
    )
}

export default WhyBox;