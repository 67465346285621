export const getPictureSrc = 'https://cbex.info/'

// account
export const getLanguage = (state) => state.language.language;
export const getText = (state) => state.language.text;
export const getIsAuth = (state) => state.account.isAuth;
export const getIsDarkTheme = (state) => state.account.isDarkTheme;
export const getProfileInfo = (state) => state.account.profile;
export const getTotalBalance = (state) => state.account.totalBalance;

// main

export const getBlogs = (state) => state.main.blogs;
export const getKycItems = (state) => state.main.kycItems;

// wallet

export const getBalances = (state) => state.wallet.balances;
export const getHistory = (state) => state.wallet.historyList;