import React from "react";
import styles from './LandingPage.module.scss';
import GetStarted from "./MainLanding/GetStarted/GetStarted";
import FollowNews from "./MainLanding/FollowNews/FollowNews";
import TradingPlatform from "./MainLanding/TradingPlatform/TradingPlatform";
import ExchangeTrade from "./MainLanding/ExchangeTrade/ExchangeTrade";
import Newsletter from "./MainLanding/Newsletter/Newsletter";
import WhyCustomers from "./MainLanding/WhyCustomers/WhyCustomers";
import {useAppSelector} from "../../hooks/redux";
import {getText} from "../../store/selectors";

const LandingPage = () => {
    const {landing} = useAppSelector(getText)

    return (
        <div className={styles.wraper}>
            <div className={styles.landingPage}>
                <GetStarted title={landing.startText} btnText={landing.getStarted} />
                <FollowNews title={landing.followTitle} viewText={landing.view} />
                <TradingPlatform title={landing.tradingTitle} subTitle={landing.tradingSubTitle} />
                <WhyCustomers text={landing} />
                <ExchangeTrade text={landing} />
                <Newsletter title={landing.newsletterTitle} text={landing.newsletterText}
                            placeholder={landing.newsletterEmail} btnText={landing.newsletterSendBtn} />
            </div>
        </div>

    )
}

export default LandingPage;