import React, {useEffect, useState} from "react";
import styles from "./TradingTable.module.scss";
import {gunzip, strFromU8} from 'fflate';
import btcIcon from '../../../../../assets/icons/bitcoin-Logo.svg';
import bnbIcon from '../../../../../assets/icons/bnbBitcoinIcon.svg';
import ethIcon from '../../../../../assets/icons/etheriumIcon.svg';
import trxIcon from '../../../../../assets/icons/trxIcon.svg';
import ltcIcon from '../../../../../assets/icons/ltcIcon.svg';
import usdcIcon from '../../../../../assets/icons/usdcIcon.svg';
import TradingTokenItem from "./TradingTokenItem";


const TradingTable = () => {
    const [btcData, setBtcData] = useState({
        fullName: 'Bitcoin',
        token: 'BTC',
        icon: btcIcon,
        open: 0,
        isUp: true,
        percentage: 0
    });
    const [ethData, setEthData] = useState({
        fullName: 'Ethereum',
        token: 'ETH',
        icon: ethIcon,
        open: 0,
        isUp: true,
        percentage: 0
    });
    const [bnbData, setBnbData] = useState({
        fullName: 'BNB',
        token: 'BNB',
        icon: bnbIcon,
        open: 0,
        isUp: true,
        percentage: 0
    });
    const [ltcData, setLtcData] = useState({
        fullName: 'Litecoin',
        token: 'LTC',
        icon: ltcIcon,
        open: 0,
        isUp: true,
        percentage: 0
    });
    const [trxData, setTrxData] = useState({
        fullName: 'Tron',
        token: 'TRX',
        icon: trxIcon,
        open: 0,
        isUp: true,
        percentage: 0
    });
    const [usdcData, setUsdcData] = useState({
        fullName: 'USD Coin',
        token: 'USDС',
        icon: usdcIcon,
        open: 0,
        isUp: true,
        percentage: 0
    });

    let save = 0;
    let saveDataEth = 0;
    let saveDataBtc = 0;
    let saveDataBnb = 0;
    let saveDataLtc = 0;
    let saveDataTrx = 0;
    let saveDataUsdc = 0;

    const setData = (value, fromSave, token) => {
        if (token === 'market.ethusdt.ticker' && fromSave !== saveDataEth) {
            saveDataEth = fromSave;
            setEthData({
                fullName: 'Ethereum',
                token: 'ETH',
                icon: ethIcon,
                open: value.open,
                isUp: value.isUp,
                percentage: value.percentage
            });
        }
        else if (token === 'market.btcusdt.ticker' && fromSave !== saveDataBtc) {
            saveDataBtc = fromSave;
            setBtcData({
                fullName: 'Bitcoin',
                token: 'BTC',
                icon: btcIcon,
                open: value.open,
                isUp: value.isUp,
                percentage: value.percentage
            });
        }
        else if (token === 'market.bnbusdt.ticker' && fromSave !== saveDataBnb) {
            saveDataBnb = fromSave;
            setBnbData({
                fullName: 'BNB',
                token: 'BNB',
                icon: bnbIcon,
                open: value.open,
                isUp: value.isUp,
                percentage: value.percentage
            });
        }
        else if (token === 'market.ltcusdt.ticker' && fromSave !== saveDataLtc) {
            saveDataBnb = fromSave;
            setLtcData({
                fullName: 'Litecoin',
                token: 'LTC',
                icon: ltcIcon,
                open: value.open,
                isUp: value.isUp,
                percentage: value.percentage
            });
        }
        else if (token === 'market.trxusdt.ticker' && fromSave !== saveDataTrx) {
            saveDataTrx = fromSave;
            setTrxData({
                fullName: 'Tron',
                token: 'TRX',
                icon: trxIcon,
                open: value.open,
                isUp: value.isUp,
                percentage: value.percentage
            });
        }
        else if (token === 'market.usdcusdt.ticker' && fromSave !== saveDataUsdc) {
            saveDataUsdc = fromSave;
            setUsdcData({
                fullName: 'USD Coin',
                token: 'USDC',
                icon: usdcIcon,
                open: value.open,
                isUp: value.isUp,
                percentage: value.percentage
            });
        }
    }

    useEffect(() => {
        if (save !== 1) {
            save = 1
            const ws = new WebSocket('wss://api.huobi.pro/ws');
            ws.onopen = function () {
                ws.send(JSON.stringify({
                    sub: 'market.btcusdt.ticker'
                }));
                ws.send(JSON.stringify({
                    sub: 'market.ethusdt.ticker',
                }));
                ws.send(JSON.stringify({
                    sub: 'market.trxusdt.ticker'
                }));
                ws.send(JSON.stringify({
                    sub: 'market.ltcusdt.ticker'
                }));
                ws.send(JSON.stringify({
                    sub: 'market.bnbusdt.ticker'
                }));
                ws.send(JSON.stringify({
                    sub: 'market.usdcusdt.ticker'
                }));
            }
            ws.onmessage = (event) => {
                const fr = new FileReader();
                fr.onload = () => {
                    gunzip(
                        new Uint8Array(fr.result),
                        (err, raw) => {
                            if (err) {
                                console.error(err);
                                return;
                            }
                            const data = JSON.parse(strFromU8(raw));
                            if (data && data.ping) {
                                ws.send(JSON.stringify({
                                    pong: data.ping
                                }))
                            } else if (data && data.tick) {
                                const percentageCalculate = data.tick.open > data.tick.close
                                    ? (data.tick.open - data.tick.close) * 100 / data.tick.close
                                    : (data.tick.close - data.tick.open) * 100 / data.tick.close
                                const newData = {
                                    open: data.tick.open,
                                    isUp: data.tick.open > data.tick.close,
                                    percentage: percentageCalculate.toFixed(3)
                                };
                                setData(newData, data.tick.open, data.ch);
                            }
                        }
                    );
                }
                fr.readAsArrayBuffer(event.data);
            }
        }
    }, [save])


    return (
        <div className={styles.table}>
            <div className={styles.header}>
                <span>Name</span>
                <span>Price</span>
                <span>24h change</span>
                <span>Trade</span>
            </div>
            <div className={styles.content}>
                <TradingTokenItem item={btcData} />
                <TradingTokenItem item={ethData} />
                <TradingTokenItem item={trxData} />
                <TradingTokenItem item={ltcData} />
                <TradingTokenItem item={bnbData} />
                <TradingTokenItem item={usdcData} />
            </div>
        </div>
    )
}

export default TradingTable;