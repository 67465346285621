import React, {useRef, useState} from "react";
import styles from './Login.module.scss';
import logo from '../../assets/images/logoAfs.svg'
import {NavLink, useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {getText} from "../../store/selectors";
import image from '../../assets/images/authImage.png';
import ReCAPTCHA from "react-google-recaptcha";
import {setLogin} from "../../api/api";
import {setAuth} from "../../store/account-slice";

const Login = () => {
    const navigate = useNavigate();
    const {signIn} = useAppSelector(getText);
    const [error, setError] = useState({email: true, password: true, captcha: true});
    const captchaRef = useRef(null);
    const dispatch = useAppDispatch();
    const [isWrong, setIsWrong] = useState({email: false, password: false});

    const { setSubmitting, handleSubmit, isSubmitting, handleChange, values, resetForm } = useFormik({
        initialValues: {
            email: '',
            password: '',
        },

        onSubmit: (values) => {
            setSubmitting(false);
            const token = captchaRef.current.getValue();
            if (token && values.password.length >= 8) {
                setIsWrong({
                    email: false,
                    password: false,
                });
                setLogin(values.email, values.password, token).then((res) => {
                    if (res && res.username) {
                        dispatch(setAuth(true));
                        navigate('/');
                    } else if (res && res.detail && res.detail === 'user_not_found') {
                        setIsWrong({
                            email: true,
                            password: isWrong.password,
                        })
                    }
                    else if (res && res.detail && res.detail === 'wrong_password') {
                        setIsWrong({
                            email: isWrong.password,
                            password: true,
                        })
                    }
                })
            } else {
                setError({email: error.email, password: error.password, captcha: false})
            }
            captchaRef.current.reset();
            resetForm();
        },
    });

    return (
        <div className={styles.login}>
            <div className={styles.leftBox}>
                {/*<img className={styles.logo} src={logo} alt=""/>*/}
                <h3>{signIn.title}</h3>
                <span className={styles.subtitle}>{signIn.subTitle}</span>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <div className={`${styles.field} ${isWrong && isWrong.email ? styles.wrong : ''}`}>
                        <label htmlFor="email">{signIn.email}</label>
                        <input id="email"
                               name="email"
                               type="email"
                               onChange={handleChange}
                               value={values.email}
                               placeholder={signIn.emailPlaceholder} />
                    </div>
                    <div className={`${styles.field} ${isWrong && isWrong.password ? styles.wrong : ''}`}>
                        <label htmlFor="password">{signIn.password}</label>
                        <input id="password"
                               name="password"
                               maxLength='64'
                               type={'password'}
                               onChange={handleChange}
                               value={values.password} placeholder={signIn.passwordPlaceholder} />
                    </div>
                    <div className={styles.field}>
                        <span className={styles.field__label}>Captcha verification</span>
                        <ReCAPTCHA
                            sitekey={"6LfWBioqAAAAAGV_vh6l7atMxhr_3_m9HpmNcFDs"}
                            ref={captchaRef}
                        />
                        {!error.captcha && (
                            <span className={styles.error}>{signIn.captchaError}</span>
                        )}
                    </div>
                    <div className={styles.action}>
                        <button type="submit" disabled={isSubmitting || values.email.length < 5 || values.password.length < 8}>
                            <span>{signIn.title.toUpperCase()}</span>
                        </button>
                    </div>
                    <div className={styles.toRegister}>
                        <span>{signIn.notHaveAccount}</span>
                        <NavLink to={'/register/'}>{signIn.signUpLink}</NavLink>
                    </div>
                </form>
            </div>
            {window.innerWidth > 767 && (
                <div className={styles.rightBox}>
                    <img src={image} alt=""/>
                </div>
            )}
        </div>
    )
}

export default Login;