import React from "react";
import styles from './FieldSelect.module.scss';
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import './FieldSelect.scss'
import {useAppSelector} from "../../../hooks/redux";
import {getIsDarkTheme} from "../../../store/selectors";

const FieldSelect = ({label, placeholder, selectList, value, setValue}) => {
    const isDark = useAppSelector(getIsDarkTheme);

    const handleChange = (value) => {
        setValue(value);
    };

    return (
        <div className={isDark ? `${styles.fieldSelect} fieldSelect` : `${styles.fieldSelectLight} fieldSelect`}>
            <div className={styles.fieldBox}>
                <label htmlFor="selectInput"><span>{label}</span></label>
                <input className={`${styles.field}`} name='selectInput' type="text" placeholder={placeholder} />
            </div>
            <div className={`${styles.select} graySelect`}>
                <Box sx={{ minWidth: 140 }}>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value.name}

                            sx={{
                                borderRadius: '8px',
                                color: '#fff',
                                '.MuiSvgIcon-root ': {
                                    fill: "white !important",
                                }
                            }}
                        >
                            {selectList && selectList.length > 0 && (
                                selectList.map((item, index) => (
                                    <MenuItem onClick={() => handleChange(item)} key={index} value={item.name}>
                                        <p className={styles.option}><img src={item.icon} alt=""/><span>{item.name}</span></p>
                                    </MenuItem>
                                ))
                            )}
                        </Select>
                    </FormControl>
                </Box>
            </div>
        </div>
    )
}

export default FieldSelect;