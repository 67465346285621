import React, {useState} from "react";
import styles from './Transfer.module.scss';
import OperationsRightBox from "../../../Common/OperationsRightBox/OperationsRightBox";
import TransferLeftBox from "./TransferLeftBox/TransferLeftBox";
import MobileChooser from "../../../Common/MobileChooser/MobileChooser";

const Transfer = () => {
    const [isLeft, setIsLeft] = useState(true)

    return (
        <div className={styles.wrapper}>
            {window.innerWidth > 743 && window.innerWidth < 1024 && (
                <MobileChooser isLeft={isLeft} setIsLeft={setIsLeft} leftText={'Workspace'}
                               rightText={'Questions and answers'}/>
            )}
            <div className={styles.transfer}>
                {window.innerWidth < 1024 && isLeft || window.innerWidth > 1023 ?
                    <div className={`${styles.transfer__leftBox} box`}>
                        <TransferLeftBox/>
                    </div> : null
                }
                {window.innerWidth < 1024 && !isLeft || window.innerWidth > 1023 ?
                    <div className={`${styles.transfer__rightBox} box`}>
                        <OperationsRightBox/>
                    </div> : null
                }
            </div>
        </div>
    )
}

export default Transfer;