import React from "react";
import styles from './LandingFooter.module.scss';
import leftDecor from '../../../assets/backgrounds/footerLeftBg.png';
import rightDecor from '../../../assets/backgrounds/fotterRightBg.png';
import logo from "../../../assets/images/logoAfs.svg";
import {NavLink, useLocation} from "react-router-dom";

const LandingFooter = ({decorHide = false}) => {
    const {pathname} = useLocation();

    return (
        <div className={decorHide ? `${styles.landingFooterMain} footerBg` : styles.landingFooter} style={decorHide ? {marginBottom: 0, marginTop: 'auto', background: '#08091C'} : null}>
            {!decorHide && pathname === '/' && (
                <img className={styles.leftDecor} src={leftDecor} alt=""/>
            )}
            {!decorHide && pathname === '/' && (
                <img className={styles.rightDecor} src={rightDecor} alt=""/>
            )}
            {decorHide && (
                <NavLink to={'/'}>
                    <img className={styles.logo} src={logo} alt=""/>
                </NavLink>
            )}
            <div className={!decorHide ? styles.landingFooter__column : styles.landingFooter__columnMain}>
                <h5>Products</h5>
                <ul>
                    <li>Spot trading</li>
                    <li>Futures trading</li>
                    <li>Options trading</li>
                    <li>WalletInstant swap</li>
                    <li>P2P trading</li>
                    <li>DeFi Staking</li>
                    <li>YukiTale Visa Card</li>
                </ul>
            </div>
            <div className={!decorHide ? styles.landingFooter__column : styles.landingFooter__columnMain}>
                <h5>Services</h5>
                <ul>
                    <li>24/7 Support chat</li>
                    <li>Copy trading</li>
                    <li>Trading bots</li>
                    <li>ETH 2.0 staking</li>
                    <li>Launchpad</li>
                    <li>Savings</li>
                    <li>YukiTale ventures</li>
                </ul>
            </div>
            <div className={!decorHide ? styles.landingFooter__column : styles.landingFooter__columnMain}>
                <h5>Information</h5>
                <ul>
                    <li>Fees</li>
                    <li>Platform status</li>
                    <li>Wallet security</li>
                    <li>Indices</li>
                    <li>Trading API</li>
                    <li>Token listing</li>
                    <li>Referral system</li>
                    <li>Bug Bounty</li>
                </ul>
            </div>
            <div className={!decorHide ? styles.landingFooter__column : styles.landingFooter__columnMain}>
                <h5>Legal</h5>
                <ul>
                    <li>Terms of service</li>
                    <li>Privacy notice</li>
                    <li>Cookies policy</li>
                    <li>AML & KYC policy</li>
                    <li>Risk Disclosure Statement</li>
                    <li>Regulatory License</li>
                    <li>Special Treatment</li>
                    <li>Law Enforcement Requests</li>
                </ul>
            </div>
        </div>
    )
}

export default LandingFooter;