import React, {useRef} from "react";
import styles from './Register.module.scss';
import logo from "../../assets/images/logoAfs.svg";
import {NavLink, useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {useAppSelector} from "../../hooks/redux";
import {getText} from "../../store/selectors";
import image from '../../assets/images/authImage.png';
import {setRegister} from "../../api/api";
import ReCAPTCHA from "react-google-recaptcha"
import {setAuth} from "../../store/account-slice";

const Register = () => {
    const navigate = useNavigate();
    const {signUp} = useAppSelector(getText);
    const captchaRef = useRef(null)

    const { setSubmitting, handleSubmit, isSubmitting, handleChange, values, resetForm } = useFormik({
        initialValues: {
            login: '',
            email: '',
            password: '',
            repeat: ''
        },

        onSubmit: (values) => {
            setSubmitting(false);
            const token = captchaRef.current.getValue();
            captchaRef.current.reset();

            setRegister(values.login, values.email, values.password, token).then((res) => {
                if (res && res.login) {
                    dispatch(setAuth(true));
                    navigate('/')
                }
            })
            resetForm();
        },
    });

    return (
        <div className={styles.register}>
            <div className={styles.leftBox}>
                <img className={styles.logo} src={logo} alt=""/>
                <h3>{signUp.title}</h3>
                <span className={styles.subtitle}>{signUp.subTitle}</span>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <div className={styles.field}>
                        <label htmlFor="login">{signUp.login}</label>
                        <input id="login"
                               name="login"
                               type="text"
                               onChange={handleChange}
                               value={values.login}
                               placeholder={signUp.loginPlaceholder} />
                    </div>
                    <div className={styles.field}>
                        <label htmlFor="email">{signUp.email}</label>
                        <input id="email"
                               name="email"
                               type={'email'}
                               onChange={handleChange}
                               value={values.email} placeholder={signUp.emailPlaceholder} />
                    </div>
                    <div className={styles.field}>
                        <label htmlFor="password">{signUp.password}</label>
                        <input id="password"
                               name="password"
                               type={'password'}
                               onChange={handleChange}
                               value={values.password} placeholder={signUp.passwordPlaceholder} />
                    </div>
                    <div className={styles.field}>
                        <label htmlFor="repeat">{signUp.passwordRepeat}</label>
                        <input id="repeat"
                               name="repeat"
                               type={'password'}
                               onChange={handleChange}
                               value={values.repeat} placeholder={signUp.passwordPlaceholder} />
                    </div>
                    <div className={styles.field}>
                        <span className={styles.field__label}>Captcha verification</span>
                        <ReCAPTCHA
                            sitekey={"6LfWBioqAAAAAGV_vh6l7atMxhr_3_m9HpmNcFDs"}
                            ref={captchaRef}
                        />
                    </div>
                    <div className={styles.action}>
                        <button type="submit"
                                disabled={isSubmitting || values.email.length < 5 || values.password.length < 5 || values.password !== values.repeat}>
                            <span>{signUp.signUp.toUpperCase()}</span>
                        </button>
                    </div>
                    <div className={styles.toRegister}>
                        <span>Don’t have an account?</span>
                        <NavLink to={'/login/'}>Sign in for free</NavLink>
                    </div>
                </form>
            </div>
            <div className={styles.rightBox}>
                <img src={image} alt=""/>
            </div>
        </div>
    )
}

export default Register;