import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isAuth: false,
    isDarkTheme: true,
    profile: {
        userName: '',
        userEmail: '',
        isEmailConfirmed: false,
        isPremium: false,
        userPhoto: null,
        id: null,
    },
    totalBalance: {
        coinBalance: 0,
        coinName: '',
        type: '',
        usdBalance: 0,
        chartFirst: 0,
        chartSecond: 0,
        chartTotal: 0,
        chartOthersValue: 100,
    },
};

export const accountSlice = createSlice({
    name: 'accountPage',
    initialState,
    reducers: {
        setTheme(state, {payload}) {
            state.isDarkTheme = payload;
        },
        setAuth(state, {payload}) {
            state.isAuth = payload;
        },
        setProfile(state, {payload}) {
            state.profile = {
                userName: payload.username,
                userEmail: payload.email,
                isEmailConfirmed: payload.email_confirmed,
                isPremium: payload.premium,
                userPhoto: payload.profile_photo,
                id: payload.id,
            }
        },
        setBalance(state, {payload}) {
            state.totalBalance = {
                coinBalance: payload.coin_balance,
                coinName: payload.coin_name,
                type: payload.type,
                usdBalance: payload.usd_balance,
                chartFirstName: payload.allocations.firstName,
                chartFirstValue: Number(payload.allocations.firstValue),
                chartSecondName: payload.allocations.secondName,
                chartSecondValue: Number(payload.allocations.secondValue),
                chartOthersValue: payload.allocations.firstValue == 0 || payload.allocations.firstValue == 0 ? 100 : 100 - payload.allocations.firstValue - payload.allocations.firstValue,
                chartSecond: 0,
                chartTotal: 0,
            };

        },
    }
})

export default accountSlice.reducer;

export const {
    setTheme,
    setAuth,
    setProfile,
    setBalance,
} = accountSlice.actions;