import React from "react";
import styles from "./TradingTable.module.scss";


const TradingTokenItem = ({item}) => {
    return (
        <div className={styles.content__row}>
            <div className={styles.content__point}>
                <p><img src={item.icon} alt=""/> {item.fullName} <span>({item.token})</span></p>
            </div>
            <div className={styles.content__point}>
                <p>$ {item.open}</p>
            </div>
            <div className={styles.content__point}>
                <p style={item.isUp ? {color: '#7AD1A5'} : {color: '#BA5349'}}>{item.percentage}%</p>
            </div>
            <div className={styles.content__point}>
                <button>
                    <span>Trade</span>
                </button>
            </div>
        </div>
    )
}

export default TradingTokenItem;