import React from "react";
import styles from './LittleChanger.module.scss';

const LittleChanger = ({token}) => {
    return (
        <div className={`${styles.littleChanger} allBtn`}>
            <button><span>All</span></button>
            <p>{token}</p>
        </div>
    )
}

export default LittleChanger;