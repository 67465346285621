import {useNavigate} from "react-router";

const baseURL = 'https://cbex.info/api/';

// AUTH

const interceptor = (status) => {
    if (status === 403) {
        setLogout().then(() => window.location.href = "/")
    }
}

export const setRegister = async (login, email, password, token) => {
    try {
        const response = await fetch(`${baseURL}auth/register`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                login: login,
                email: email,
                password: password,
                token: token,
            })
        });
        return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const setLogout = async () => {
    try {
        const response = await fetch(`${baseURL}auth/logout`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        // const result = 'ok'
        return 'logout';

        // return await response.json();
    } catch (error) {
        console.log(error);
    }
}

export const setLogin = async (email, password, token) => {
    try {
        const response = await fetch(`${baseURL}auth/login`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                password: password,
                token: token,
            }),
            credentials: "include",
        });
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

// MAIN PAGE

export const getUserInfo = async () => {
    try {
        const response = await fetch(`${baseURL}user/profile/info`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getUserBalances = async () => {
    try {
        const response = await fetch(`${baseURL}user/profile/balance`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}

export const getUserTotalBalances = async () => {
    try {
        const response = await fetch(`${baseURL}user/profile/total/balance`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            credentials: "include",
        });
        interceptor(response.status);
        const result = await response.json();

        return await result;
    } catch (error) {
        console.log(error);
    }
}