import React, {useState} from "react";
import styles from './TokenChanger.module.scss';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useAppSelector} from "../../../hooks/redux";
import {getIsDarkTheme} from "../../../store/selectors";

const TokenChanger = ({tokenList}) => {
    const isDark = useAppSelector(getIsDarkTheme);

    const [token, setToken] = useState(tokenList[0].fullName);

    const handleChange = (event) => {
        setToken(event.target.value);
    };


    return (
        <div className={`${styles.tokenChanger} tokenChanger`}>
            <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={token}
                        onChange={handleChange}
                        sx={{
                            borderRadius: '11px',
                            borderBlock: '#FFFFFF80',
                            color: '#fff',
                            '.MuiSvgIcon-root ': isDark ? {
                                fill: "white !important",
                            } : null,
                        }}
                    >
                        {tokenList && tokenList.length > 0 && (
                            tokenList.map((item, index) => (
                                <MenuItem key={index} value={item.fullName}>
                                    <p className={styles.option}><img src={item.icon} alt=""/><span>{item.fullName}</span></p>
                                </MenuItem>
                            ))
                        )}
                    </Select>
                </FormControl>
            </Box>
            {window.innerWidth > 1023 && (
                <div className={styles.tokenChangerButtons}>
                    {tokenList && tokenList.length > 0 && (
                        tokenList.map((item, index) => (
                            <div key={index} className={`${styles.tokenChangerButtons__toggle} toggleRadio`}>
                                <span>{item.token}</span>
                                <input type="radio" name='tokenChanger' checked={token === item.fullName} onChange={() => setToken(item.fullName)} />
                                <p>{item.token}</p>
                            </div>
                        ))
                    )}
                </div>
            )}
        </div>
    )
}

export default TokenChanger